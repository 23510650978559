import { NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { FmntsFormErrorComponent } from './form-error.component';
import { FmntsFormFieldComponent } from './form-field.component';
import { FmntsHintComponent } from './hint.component';
import { FmntsLabelComponent } from './label.component';

@NgModule({
  declarations: [FmntsFormFieldComponent],
  exports: [
    FmntsFormErrorComponent,
    FmntsFormFieldComponent,
    FmntsHintComponent,
    FmntsLabelComponent,
  ],
  imports: [
    FmntsIconsModule,
    NgTemplateOutlet,
    FmntsHintComponent,
    FmntsLabelComponent,
    FmntsFormErrorComponent,
  ],
})
export class FmntsFormFieldModule {}
