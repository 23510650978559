<input
  #fileInput
  type="file"
  [style.display]="'none'"
  [accept]="accepts() | join: ','"
  [attr.capture]="capture()"
  (change)="updateFile(fileInput)"
/>

<div class="fmnts-image-input__buttons">
  <!-- action buttons from template-->
  @if (!uploaded) {
    <ng-content select="[needsEmptyImage]" />
  } @else {
    <ng-content select="[needsImage]" />
  }
  <ng-content />
</div>
