import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  OnInit,
  QueryList,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  FmntsTextInputPrefixDirective,
  htmlIdMaker,
} from '@fmnts/components/core';
import { FmntsFormFieldControl } from '@fmnts/components/form-field';
import { FmntsIconsModule } from '@fmnts/components/icons';
import {
  FmntsInputDirective,
  FmntsTextFieldComponent,
} from '@fmnts/components/input';
import { isNil, isNotNullish, PrimitiveNullUndefined } from '@fmnts/core';
import {
  FMNTS_SELECT_OPTION_PARENT_COMPONENT,
  OptionComponent,
} from './option.component';
import { fmntsSelectAnimations } from './select-animations';
import { SelectBaseComponent } from './select-base';

const selectIds = htmlIdMaker('fmnts-select');

/**
 * Select Component
 *
 * @example
 * ```html
 * <fmnts-select
 *  formControlName="control"
 * >
 *  <fmnts-select-option *ngFor="let option of options" [value]="option.id">
 *    {{ option.label }}
 *  </fmnts-select-option>
 *
 * </fmnts-select>
 * ```
 */
@Component({
  selector: `fmnts-select, input[fmnts-select]`,
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  imports: [
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    FmntsIconsModule,
    FmntsInputDirective,
    FmntsTextFieldComponent,
    FmntsTextInputPrefixDirective,
    ReactiveFormsModule,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fmntsSelectAnimations.rotateIcon],
  providers: [
    {
      provide: FMNTS_SELECT_OPTION_PARENT_COMPONENT,
      useExisting: SelectComponent,
    },
    { provide: FmntsFormFieldControl, useExisting: SelectComponent },
  ],
})
export class SelectComponent
  extends SelectBaseComponent<PrimitiveNullUndefined, PrimitiveNullUndefined>
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  @ContentChildren(OptionComponent, { descendants: true })
  override options!: QueryList<OptionComponent<PrimitiveNullUndefined>>;

  protected readonly typeAheadControl = new FormControl<string>('', {
    nonNullable: true,
  });
  override readonly _id = signal(selectIds.next().value);

  protected override readonly _clearValue: PrimitiveNullUndefined = null;
  /** Selected value */
  public value: PrimitiveNullUndefined = this._clearValue;

  public override multiple = false;
  public hasTypeaheadResult = true;

  private get selected() {
    return this.selectionModel?.selected[0];
  }

  override get empty(): boolean {
    return this.value === this._clearValue;
  }

  /**
   * Formatted Value
   */
  public get formattedValue(): string {
    return this.selectionModel.selected[0]?.displayValue || '';
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // Listen to changes in typeahead search-control and filter options
    this.typeAheadControl.valueChanges.subscribe((searchString) =>
      this.hasAsyncTypeahead()
        ? this._triggerAsyncSearch(searchString)
        : this.filterForTypeAhead(searchString),
    );
  }

  writeValue(newValue: PrimitiveNullUndefined): void {
    if (newValue !== this.value) {
      if (this.options) {
        this._setSelectionByValue(newValue);
      }

      this.value = newValue;
    }
  }

  /**
   * Finds and selects and option based on its value.
   * @returns Option that has the corresponding value.
   */
  protected override _selectOptionsByValue(
    value: PrimitiveNullUndefined,
  ): OptionComponent<PrimitiveNullUndefined>[] {
    const correspondingOption = this.options.find((option: OptionComponent) => {
      // Skip options that are already in the model
      if (this.selectionModel.isSelected(option)) {
        return false;
      }

      // Treat null as a special reset value.
      return (
        isNotNullish(option.value) && this._compareWith(option.value, value)
      );
    });

    if (correspondingOption) {
      this.selectionModel.select(correspondingOption);
    }

    return correspondingOption ? [correspondingOption] : [];
  }

  protected override _onSelect(
    option: OptionComponent<PrimitiveNullUndefined>,
    isUserInput: boolean,
  ): void {
    const wasSelected = this.selectionModel.isSelected(option);

    if (isNil(option.value) && !this.multiple) {
      option.deselect();
      this.selectionModel.clear();

      if (isNotNullish(this.value)) {
        this._propagateChanges(option.value);
      }
    } else {
      if (wasSelected !== option.selected) {
        if (option.selected) {
          this.selectionModel.select(option);
        } else {
          this.selectionModel.deselect(option);
        }
      }
    }

    if (wasSelected !== this.selectionModel.isSelected(option)) {
      this._propagateChanges();
    }
  }

  private _propagateChanges(fallbackValue?: PrimitiveNullUndefined): void {
    this.propagateValueChange(
      this.selected ? this.selected.value : fallbackValue,
    );
  }

  /**
   * Filter values for typeahead
   *
   * Sets options as `disabled`, if they do not match with the
   * search query.
   */
  private filterForTypeAhead(value: string) {
    if (!this.showSearchField()) {
      return;
    }
    this.hasTypeaheadResult = false;
    this.options.forEach((option) => {
      // set options to disabled, if they do not match with typeahead
      const matchedOption =
        option.displayValue?.toLowerCase().includes(value.toLowerCase()) ??
        true;
      option.hidden = !matchedOption;

      if (matchedOption && !option.selected) {
        this.hasTypeaheadResult = true;
      }
    });
    this.cd.markForCheck();
  }
  /**
   * Notifies that the search term has changed. This is used for querying options.
   * @param searchTerm
   * @returns
   */
  private _triggerAsyncSearch(searchTerm: string) {
    if (!this.showSearchField()) {
      return;
    }
    this.searchTermChange.emit(searchTerm);
  }
}
