import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  booleanAttribute,
  computed,
  inject,
  input,
} from '@angular/core';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { FmntsCheckboxState } from './checkbox.model';
import { FMNTS_CHECKBOX_ICONS } from './checkbox.tokens';

/**
 * Displays a visual representation of a checkbox state.
 */
@Component({
  selector: 'fmnts-pseudo-checkbox',
  templateUrl: './pseudo-checkbox.component.html',
  styleUrls: ['./pseudo-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FmntsIconsModule],
  host: {
    class: 'fmnts-pseudo-checkbox',
    '[class.fmnts-pseudo-checkbox--checked]': 'state() === "checked"',
    '[class.fmnts-pseudo-checkbox--disabled]': 'disabled()',
    '[class.fmnts-pseudo-checkbox--readonly]': 'readonly()',
  },
})
export class PseudoCheckboxComponent {
  protected readonly icons = inject(FMNTS_CHECKBOX_ICONS);

  /** State of the checkbox */
  readonly state = input.required<FmntsCheckboxState>();

  /** Disables the checkbox. */
  readonly disabled = input(false, { transform: booleanAttribute });
  /** Readonly state*/
  readonly readonly = input(false, { transform: booleanAttribute });

  protected readonly stateIcon = computed(() => this.icons.state[this.state()]);
}
