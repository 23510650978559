import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  InjectionToken,
  Input,
  Output,
  ViewEncapsulation,
  booleanAttribute,
  computed,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { Checkbox, PseudoCheckboxComponent } from '@fmnts/components/checkbox';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { PrimitiveNullUndefined } from '@fmnts/core';

/**
 * Interface for a parent component for options, containing
 * option configuration.
 * E.g. `SelectComponent` or `MultiSelectComponent`
 */
export interface OptionParentComponent {
  /** Whether the parent component allows to select multiple options */
  readonly multiple: boolean;
}

export class OptionSelectionChange<T> {
  constructor(
    /** Reference to the option that emitted the event. */
    public source: OptionComponent<T>,
    /** Whether all options should be deselected */
    public clear: boolean,
    /** Whether the change was made by the user. */
    public isUserInput: boolean,
  ) {}
}

/**
 * Injection token used to provide the parent component to options.
 */
export const FMNTS_SELECT_OPTION_PARENT_COMPONENT =
  new InjectionToken<OptionParentComponent>(
    '@fmnts.components.select.option_parent_component',
  );

@Component({
  selector: `fmnts-select-option, option[fmnts-select-option]`,
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PseudoCheckboxComponent, FmntsIconsModule],
  host: {
    '[class.fmnts-select-option--disabled]': 'disabled()',
    '[class.fmnts-select-option--hidden]': 'shouldBeHidden',
    '[class.fmnts-select-option--multiple]': 'multiple',
    '[class.fmnts-select-option--selected]': '_selected()',
  },
})
export class OptionComponent<T = PrimitiveNullUndefined> {
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly cd = inject(ChangeDetectorRef);
  public readonly parent = inject(FMNTS_SELECT_OPTION_PARENT_COMPONENT, {
    optional: true,
  });

  /** Whether the option is disabled. */
  readonly disabled = input(false, { transform: booleanAttribute });

  /** Whether the option is visible. */
  public hidden = false;
  protected get shouldBeHidden(): boolean {
    return this.hidden && !(this.selected || this.isNoOptionsAvailableOption);
  }

  /** Whether the wrapping component is in multiple selection mode. */
  get multiple(): boolean {
    return this.parent?.multiple === true;
  }

  /** Whether the option is selected. */
  get selected(): boolean {
    return this._selected();
  }
  protected readonly _selected = signal(false);
  protected readonly checkboxState = computed(() =>
    Checkbox.fromBoolean(this._selected()),
  );

  /** The form value of the option. */
  @Input() value: T | null | undefined;

  /** Event emitted when the option is selected or deselected. */
  @Output() readonly selectionChange = new EventEmitter<
    OptionSelectionChange<T>
  >();

  /** Element containing the option's text. */
  protected readonly _text =
    viewChild.required<ElementRef<HTMLElement>>('text');

  @HostListener('click')
  protected clickOption(): void {
    if (this.isNoOptionsAvailableOption) {
      return;
    }
    if (this.disabled()) {
      return;
    }

    const selected = this.isDeselectOption
      ? false
      : this.multiple
        ? !this._selected()
        : true;
    this._selected.set(selected);
    this.cd.markForCheck();

    // If option with 'deselect' attribute is clicked,
    // emit `deselect = true`
    this._emitSelectionChangeEvent(this.isDeselectOption, true);
  }

  /**
   * The displayed value of the option. It is necessary to show the selected option in the
   * select's trigger.
   */
  get displayValue(): string {
    return this._displayValue();
  }
  readonly _displayValue = computed(() =>
    (this._text().nativeElement.textContent || '').trim(),
  );

  protected get isDeselectOption(): boolean {
    return this._elementRef.nativeElement.hasAttribute('deselect');
  }

  private get isNoOptionsAvailableOption() {
    return this._elementRef.nativeElement.hasAttribute('noavailableoptions');
  }

  /** Selects the option. */
  public select(): void {
    if (!this._selected()) {
      this._selected.set(true);
      this.cd.markForCheck();
      this._emitSelectionChangeEvent();
    }
  }

  /** Deselects the option. */
  public deselect(): void {
    if (this._selected()) {
      this._selected.set(false);
      this.cd.markForCheck();
      this._emitSelectionChangeEvent();
    }
  }

  /** Emits the selection change event. */
  private _emitSelectionChangeEvent(clear = false, isUserInput = false): void {
    this.selectionChange.emit(
      new OptionSelectionChange<T>(this, clear, isUserInput),
    );
  }
}
