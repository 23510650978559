/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  contentChild,
  Directive,
  DoCheck,
  inject,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import {
  AbstractControlDirective,
  FormGroupDirective,
  NgControl,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher, mixinErrorState } from '@fmnts/components/core';
import { ReplaySubject, Subject } from 'rxjs';
import { FmntsFormFieldControl } from './form-field-control';

const _BaseFormFieldControl = mixinErrorState(
  class {
    /**
     * Emits whenever the component state changes and should cause the parent
     * form field to update. Implemented as part of `FmntsFormFieldControl`.
     * @docs-private
     */
    readonly stateChanges = new Subject<void>();

    public readonly _defaultErrorStateMatcher = inject(ErrorStateMatcher);
    /** Enclosing form for this control. */
    public readonly parentForm = inject(NgForm, { optional: true });

    /** Enclosing form group for this control. */
    public readonly parentFormGroup = inject(FormGroupDirective, {
      optional: true,
    });

    /** Gets the AbstractControlDirective for this control. */
    public readonly ngControl: NgControl | AbstractControlDirective | null =
      null;
  },
);

/** An abstract implementatino which allows a control to work inside of a `FmntsFormField`. */
@Directive()
export abstract class AbstractFormFieldControl<T>
  extends _BaseFormFieldControl
  implements OnChanges, OnDestroy, DoCheck, FmntsFormFieldControl<T>
{
  /** The value of the control. */
  abstract value: T | null;

  /** The element ID for this control. */
  abstract readonly id: string;

  /** The placeholder for this control. */
  @Input() placeholder: string | null = null;

  /** Whether the control is focused. */
  get focused(): boolean {
    return this._focused;
  }
  set focused(value: boolean) {
    this._focused = value;
  }
  private _focused = false;

  /** Whether the control is empty. */
  abstract readonly empty: boolean;

  /** Whether the control is required. */
  abstract readonly required: boolean;

  /** Whether the control is disabled. */
  abstract disabled: boolean;

  /** Whether the control is readonly. */
  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;

  /** Whether the control is in an error state. */
  // abstract override readonly errorState: boolean;
  override errorState = false;

  @Input()
  override errorStateMatcher!: ErrorStateMatcher;

  /**
   * An optional name for the control type that can be used to distinguish `fmnts-form-field` elements
   * based on their control type. The form field will add a class,
   * `fmnts-form-field-type-{{controlType}}` to its root element.
   */
  readonly controlType?: string;

  /**
   * Value of `aria-describedby` that should be merged with the described-by ids
   * which are set by the form-field.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') readonly userAriaDescribedBy?: string;

  /** Sets the list of element IDs that currently describe this control. */
  abstract setDescribedByIds(ids: string[]): void;

  /** Handles a click on the control's container. */
  abstract onContainerClick(event: MouseEvent): void;

  private readonly _destroyed$ = new ReplaySubject<boolean>(1);

  /** Can be used to listen for disposal. */
  readonly destroyed$ = this._destroyed$.asObservable();

  ngOnChanges(): void {
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
    this._updateDisabledState();
  }

  protected _updateDisabledState(): void {
    // Since the input isn't a `ControlValueAccessor`, we don't have a good way of knowing when
    // the disabled state has changed. We can't use the `ngControl.statusChanges`, because it
    // won't fire if the input is disabled with `emitEvents = false`, despite the input becoming
    // disabled.
    const ngControlDisabled = this.ngControl?.disabled ?? null;
    if (ngControlDisabled === null || ngControlDisabled === this.disabled) {
      return;
    }

    this.disabled = ngControlDisabled;
    this.stateChanges.next();
  }

  /** Callback for the cases where the focused state of the input changes. */
  protected _setFocusedState(isFocused: boolean): void {
    if (isFocused === this.focused) {
      return;
    }

    this.focused = isFocused;
    this.stateChanges.next();
  }
}

/**
 * Abstract implementation that can be used as a base for implementing a `FormFieldComponent`.
 */
@Directive({})
export abstract class AbstractFormFieldComponent {
  /**
   * Inner form field control.
   */
  protected readonly formFieldControl = contentChild.required<
    FmntsFormFieldControl<unknown>
  >(FmntsFormFieldControl);

  /** Gets the current form field control */
  get control(): FmntsFormFieldControl<unknown> {
    return this._explicitFormFieldControl || this.formFieldControl();
  }
  set control(value: FmntsFormFieldControl<unknown>) {
    this._explicitFormFieldControl = value;
  }

  /** Set when a form field control was explicitly passed. */
  private _explicitFormFieldControl?: FmntsFormFieldControl<unknown>;

  /**
   * Determines whether a class from the AbstractControlDirective
   * should be forwarded to the host element.
   */
  protected shouldForward(prop: keyof AbstractControlDirective): boolean {
    const control = this.control?.ngControl ?? null;
    return !!control?.[prop];
  }
}
