<ng-template #labelTemplate>
  <label [id]="_labelId" [attr.for]="control.id" class="p-0 m-0">
    <ng-content select="fmnts-label" />
  </label>
</ng-template>

<ng-template [ngTemplateOutlet]="labelTemplate" />

<ng-content />

@switch (_getDisplayedMessages()) {
  @case ('error') {
    <ng-content select="fmnts-form-error, [fmntsFormError]" />
  }
  @case ('hint') {
    <ng-content select="fmnts-hint" />
  }
}
