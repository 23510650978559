import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanToString, isNil, isString } from '@fmnts/core';
import * as Arr from 'effect/Array';
import * as Fn from 'effect/Function';
import * as O from 'effect/Option';
import * as Order from 'effect/Order';
import * as P from 'effect/Predicate';
import * as Rec from 'effect/Record';

type Key = string | number | symbol;

interface DefaultHeaderOptions {
  force?: boolean;
}

type QueryParamValue = string | number | boolean;
type QueryParam = QueryParamValue | ReadonlyArray<QueryParamValue>;
type NullableQueryParam = QueryParamValue | ReadonlyArray<QueryParamValue>;

type QueryParams = Record<string, NullableQueryParam>;

type TQueryParams<T> = {
  [K in keyof T]: T[K] extends NullableQueryParam ? T[K] : never;
};

@Injectable({
  providedIn: 'root',
})
export class ApiRequestHelper {
  public makeDefaultHeaders(options: DefaultHeaderOptions): HttpHeaders {
    let result = new HttpHeaders();

    if (options.force) {
      result = result.set('x-refresh', `${true}`);
    }

    return result;
  }

  public makeParams<T>(urlOrFilter: string | T): HttpParams;
  public makeParams(urlOrFilter: string | Record<Key, CanToString>): HttpParams;

  public makeParams(
    urlOrFilter: string | Record<Key, CanToString>,
  ): HttpParams {
    const paramObj = isString(urlOrFilter)
      ? this._paramsFromUrl(urlOrFilter)
      : (urlOrFilter ?? {});

    return Object.keys(paramObj)
      .sort()
      .reduce((p, key) => {
        const value = paramObj[key];
        return isNil(value) ? p : p.set(key, value.toString());
      }, new HttpParams());
  }

  public makeQueryParams<T>(query: TQueryParams<T>): HttpParams;
  public makeQueryParams(query: Readonly<QueryParams>): HttpParams;
  public makeQueryParams(query: Readonly<QueryParams>): HttpParams {
    const filterObj = Fn.pipe(
      query,
      Rec.filterMap(O.liftPredicate(P.isNotNullable)),
      Rec.toEntries,
      Arr.sort(
        Order.mapInput(
          Order.string,
          ([key]: [key: string, value: QueryParam]) => key,
        ),
      ),
      Rec.fromEntries,
    );

    return new HttpParams({ fromObject: filterObj });
  }

  private _paramsFromUrl(url: string): Record<string, CanToString> {
    const newFilters: Record<string, CanToString> = {};
    // The fake for a base that is passed here allows us to handle
    // URLs that are only a path, like `/some/path?with=params`
    // since we're only interested in the params here, it doesn't
    // really matter
    new URL(url, 'https://fake.base').searchParams.forEach(
      (value, key) => (newFilters[key] = value),
    );

    return newFilters;
  }
}
