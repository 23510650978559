<ng-template #labelTemplate>
  <ng-content select="fmnts-label" />
</ng-template>

<label class="fmnts-text-input__wrapper">
  <span class="fmnts-text-input__prefix">
    <ng-content select="[fmntsFieldPrefix]" />
  </span>

  <div class="fmnts-text-input__infix">
    <ng-template [ngTemplateOutlet]="labelTemplate" />

    <ng-content />
  </div>

  <span class="fmnts-text-input__suffix">
    <ng-content select="[fmntsFieldSuffix]" />
  </span>
</label>
