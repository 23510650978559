import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { Observable } from 'rxjs';
import * as Api from './api-model';

@Injectable({ providedIn: 'root' })
export class AgencyApi {
  private readonly http = inject(HttpClient);
  private readonly apiHelper = inject(ApiRequestHelper);
  private readonly apiConfig = inject(ApiConfigService);

  static readonly rootUrl = '/v2/territory/agencies';

  list(query: Api.AgencyListQuery): Observable<ReadonlyArray<Api.AgencyRef>> {
    const url = this.apiConfig.buildCockpitApiUrl([AgencyApi.rootUrl]);
    const params = this.apiHelper.makeParams(query);

    return this.http.get<ReadonlyArray<Api.AgencyRef>>(url, { params });
  }
}
