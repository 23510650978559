import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as O from 'effect/Option';
import { MatomoTracker } from 'ngx-matomo-client';
import { EMPTY, Observable } from 'rxjs';
import {
  SHARED_VENDORS_MATOMO_TITLE_STREAM,
  SHARED_VENDORS_MATOMO_USER_STREAM,
} from './matomo-interop.tokens';
import { MatomoUser } from './matomo.model';

/**
 * Service that provides interop with Matomo tracker.
 */
@Injectable()
export class MatomoInteropService {
  private readonly tracker = inject(MatomoTracker);

  constructor() {
    this._setupTitleState();
    this._setupUserState();
  }

  /**
   * Sets up syncing the page title with Matomo tracker.
   */
  private _setupTitleState(
    titleFeature = O.fromNullable(
      inject(SHARED_VENDORS_MATOMO_TITLE_STREAM, { optional: true }),
    ),
  ): void {
    this._fromOptionalFeatureFactory(titleFeature)
      .pipe(takeUntilDestroyed())
      .subscribe((title) => {
        this.tracker.setDocumentTitle(title);
      });
  }

  private _setupUserState(
    userFeature = O.fromNullable(
      inject(SHARED_VENDORS_MATOMO_USER_STREAM, { optional: true }),
    ),
  ): void {
    this._fromOptionalFeatureFactory(userFeature)
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        this.updateUser(user);
      });
  }

  private updateUser(user: O.Option<MatomoUser>): void {
    if (O.isSome(user)) {
      this.tracker.setUserId(user.value.id);
    } else {
      this.tracker.resetUserId();
    }
  }

  private _fromOptionalFeatureFactory<T>(
    feature: O.Option<Observable<T>>,
  ): Observable<T> {
    return feature.pipe(O.getOrElse(() => EMPTY));
  }
}
