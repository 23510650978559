import { SelectionModel } from '@angular/cdk/collections';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { Overlay, ScrollStrategy, ViewportRuler } from '@angular/cdk/overlay';
import {
  AfterContentInit,
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  WritableSignal,
  booleanAttribute,
  contentChild,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { classnames } from '@fmnts/common';
import { ChangeCallback, TouchedCallback } from '@fmnts/common/forms';
import {
  AbstractFormFieldControl,
  FmntsLabelComponent,
} from '@fmnts/components/form-field';
import * as Fn from 'effect/Function';
import {
  Observable,
  Subject,
  defer,
  merge,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  OptionComponent,
  OptionParentComponent,
  OptionSelectionChange,
} from './option.component';
import { FMNTS_SELECT_I18N, FMNTS_SELECT_ICONS } from './select.tokens';

type CompareFn = (a: unknown, b: unknown) => boolean;

export const FMNTS_SELECT_SCROLL_STRATEGY = new InjectionToken<
  Fn.LazyArg<ScrollStrategy>
>('@fmnts/components/select/scroll-strategy', {
  providedIn: 'root',
  factory: () => {
    const overlay = inject(Overlay);
    return () => overlay.scrollStrategies.reposition();
  },
});

type SelectValueDisplay = 'value' | 'label';
/**
 * Width of the panel. If set to `auto`, the panel will match the trigger width.
 * If set to `hug`, the panel will grow to match the longest option's text.
 */
type SelectPanelWidth = 'auto' | 'hug' | string | number;
/** Object that can be used to configure the default options for the select module. */
export interface FmntsSelectConfig {
  /** Class or list of classes to be applied to the menu's overlay panel. */
  readonly overlayPanelClass?: string | string[];

  /**
   * Width of the panel. If set to `auto`, the panel will match the trigger width.
   * If set to `hug`, the panel will grow to match the longest option's text.
   */
  readonly panelWidth?: SelectPanelWidth;
}

export const FMNTS_SELECT_CONFIG = new InjectionToken<FmntsSelectConfig>(
  '@fmnts/components/select/select-config',
  {
    factory: (): FmntsSelectConfig => ({
      overlayPanelClass: '',
      panelWidth: 'auto',
    }),
  },
);

@Directive({
  host: {
    '[attr.disabled]': 'disabled || null',
    '[attr.aria-disabled]': 'disabled.toString()',
  },
})
export abstract class SelectBaseComponent<TValue, TOptionValue = TValue>
  extends AbstractFormFieldControl<TValue>
  implements
    AfterContentInit,
    OptionParentComponent,
    OnDestroy,
    OnInit,
    ControlValueAccessor,
    DoCheck,
    OnChanges
{
  /** The unique ID for the radio button. */
  abstract _id: WritableSignal<string>;
  public abstract multiple: boolean;
  public abstract options: QueryList<OptionComponent<TOptionValue>>;

  /** Value to set when the value is cleared.  */
  protected abstract readonly _clearValue: TValue;

  // In order to get the connected `NgControl` for this component we
  // need to provide this component instance as the `NG_VALUE_ACCESSOR`
  // instead of using `providers` to avoid circular dependencies.
  // Declare the `ControlValueAccessor` interface as abstract here so
  // that extending classes provide these methods.
  abstract writeValue(obj: unknown): void;

  protected readonly cfg = inject(FMNTS_SELECT_CONFIG);
  protected readonly icons = inject(FMNTS_SELECT_ICONS);
  protected readonly i18n = inject(FMNTS_SELECT_I18N);
  private readonly _viewportRuler = inject(ViewportRuler);
  private readonly _destroy = inject(DestroyRef);
  public readonly cd = inject(ChangeDetectorRef);

  protected readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  override readonly ngControl = inject(NgControl, {
    optional: true,
    self: true,
  });

  /** Deals with the selection logic. */
  protected selectionModel!: SelectionModel<OptionComponent<TOptionValue>>;

  protected readonly _label = contentChild(FmntsLabelComponent, {
    descendants: true,
  });

  @HostBinding('class.fmnts-select') protected readonly componentClass =
    'fmnts-select';

  /**
   * Disable the select input
   */
  @Input({ transform: booleanAttribute })
  @HostBinding('class.fmnts-select--disabled')
  disabled = false;

  /** Classes to be passed to the select panel. */
  readonly panelClass = input<string>();
  protected readonly _overlayPanelClass = this.cfg.overlayPanelClass ?? '';

  /** Whether the component is required. */
  @Input({ transform: booleanAttribute })
  get required(): boolean {
    return (
      this._required ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }
  set required(value: boolean) {
    this._required = value;
    this.stateChanges.next();
  }
  private _required: boolean | undefined;

  /** The unique ID for the radio button. */
  @HostBinding('attr.id')
  @Input()
  get id(): string {
    return this._id();
  }
  set id(value: string) {
    this._id.set(value);
  }

  /**
   * Whether the select should prefer displaying the value or the label/placeholder.
   */
  readonly valueDisplay = input<SelectValueDisplay>('value');

  /** for non-required inputs it should be possible to deselect value. */
  readonly allowDeselect = input(false, { transform: booleanAttribute });

  /** Whether typeahead function is activated. */
  readonly showSearchField = input(false, { transform: booleanAttribute });

  /** Placeholder for search field. */
  readonly searchInputPlaceholder = input('', { alias: 'typeAheadText' });

  /** Whether the type ahead is async. */
  public hasAsyncTypeahead = input(false, { transform: booleanAttribute });
  /** Whether the options are currently being loaded. */
  public isLoadingOptions = input(false, { transform: booleanAttribute });
  /** Typeahead value changed */
  public searchTermChange = output<string>();

  /**
   * Event that emits whenever the raw value of the select changes. This is here primarily
   * to facilitate the two-way binding for the `value` input.
   * @docs-private
   */
  @Output() readonly valueChange = new EventEmitter<TValue>();

  /**
   * Event emitted when the select panel has been toggled.
   */
  @Output()
  readonly openedChange = new EventEmitter<boolean>();

  private readonly _initialized = new Subject<void>();

  /**
   * Width of the panel. If set to `auto`, the panel will match the trigger width.
   * If set to `hug` the panel will grow to match the longest option's text.
   */
  readonly panelWidth = input<SelectPanelWidth>(this.cfg.panelWidth ?? 'auto');

  /** Width of the whole field. */
  protected readonly _fieldWidth = signal<number | ''>('');
  /** Width of the overlay panel. */
  protected readonly _overlayWidth = signal<string | number>('');

  /** Factory function used to create a scroll strategy for this select. */
  private readonly _scrollStrategyFactory = inject(
    FMNTS_SELECT_SCROLL_STRATEGY,
  );
  protected readonly _scrollStrategy = this._scrollStrategyFactory();

  /** Combined stream of all of the child options' change events. */
  protected readonly optionSelectionChanges: Observable<
    OptionSelectionChange<TOptionValue>
  > = defer(() => {
    const { options } = this;

    if (options) {
      return options.changes.pipe(
        startWith(options),
        switchMap(() =>
          merge(...options.map((option) => option.selectionChange)),
        ),
      );
    }

    return this._initialized.pipe(switchMap(() => this.optionSelectionChanges));
  });

  /**
   * Whether or not the select panel is open.
   */
  get panelOpen(): boolean {
    return this._panelOpen();
  }
  protected readonly _panelOpen = signal(false);

  /**
   * Whether the select is focused.
   */
  @HostBinding('class.fmnts-select--focused')
  override get focused(): boolean {
    return super.focused || this.panelOpen;
  }

  /**
   * `true` if the connected `FormControl` is invalid.
   */
  @HostBinding('class.fmnts-select--invalid')
  public get invalid(): boolean {
    return (this.ngControl?.touched && this.ngControl?.invalid) ?? false;
  }

  @HostBinding('class')
  protected get hostClasses(): string {
    const prefix = this.componentClass;
    return classnames([this._label() && `${prefix}--has-label`]);
  }

  /**
   * Function that is used to compare values with each other.
   * These could be values selected or values from the options.
   * The function should return `true` when the passed values
   * are considered to be the same.
   */
  @Input()
  get compareWith(): CompareFn {
    return this._compareWith;
  }
  set compareWith(fn: CompareFn) {
    this._compareWith = fn;
    if (this.selectionModel) {
      // A different comparator means the selection could change.
      this._initializeSelection();
    }
  }

  /**
   * Function that is used to compare to values with each other.
   * These could be values selected or values from the options.
   *
   * @param a
   * @param b
   *
   * @returns
   * When `true` is returned the to values `a` and `b` are considered
   * to be the same.
   */
  protected _compareWith: CompareFn = (a, b) =>
    // eslint-disable-next-line eqeqeq
    a == b;
  protected _onChange: ChangeCallback<TValue> = () => {};
  protected _onTouched: TouchedCallback = () => {};

  constructor() {
    super();

    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.selectionModel = new SelectionModel<OptionComponent<TOptionValue>>(
      this.multiple,
    );
    this._viewportRuler
      .change()
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe(() => {
        this._fieldWidth.set(this._getFieldWidth());
        if (this.panelOpen) {
          this._overlayWidth.set(this._getOverlayWidth());
          this.cd.detectChanges();
        }
      });
  }

  ngAfterContentInit(): void {
    this._initialized.next();
    this._initialized.complete();

    // Listen to Model changes, and update component value accordingly
    this.selectionModel.changed
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event) => {
        event.added.forEach((option) => option.select());
        event.removed.forEach((option) => option.deselect());
        this.cd.markForCheck();
      });

    // Initialize and listen to changes in options
    this.options.changes
      .pipe(startWith(null), takeUntil(this.destroyed$))
      .subscribe(() => {
        this._resetOptions();
        this._initializeSelection();
      });
  }

  registerOnChange(fn: ChangeCallback): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: TouchedCallback): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  protected _handleOverlayKeydown(ev: KeyboardEvent): void {
    if (ev.keyCode === ESCAPE && !hasModifierKey(ev)) {
      ev.preventDefault();
      this.close();
    }
  }

  // _onFocus(): void {
  //   if (!this.disabled) {
  //     this._focused = true;
  //     this.stateChanges.next();
  //   }
  // }

  /**
   * Calls the touched callback only if the panel is closed. Otherwise, the trigger will
   * "blur" to the panel when it opens, causing a false positive.
   */
  // _onBlur(): void {
  //   this._focused = false;

  //   if (!this.disabled && !this.panelOpen) {
  //     this._onTouched();
  //     this.cd.markForCheck();
  //     this.stateChanges.next();
  //   }
  // }

  /**
   * Toggles the dropdown for the select input
   */
  public toggleInput(): void {
    if (this.panelOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  public close(): void {
    if (!this.panelOpen) {
      return;
    }

    this._panelOpen.set(false);
    this.cd.markForCheck();
    this._onTouched();
    this.openedChange.emit(false);
  }

  public open(): void {
    if (this.disabled || this.panelOpen) {
      return;
    }

    this._fieldWidth.set(this._getFieldWidth());
    this._overlayWidth.set(this._getOverlayWidth());
    this._panelOpen.set(true);
    this.cd.markForCheck();
    this.openedChange.emit(true);
  }

  private _getOverlayWidth(): string | number {
    switch (this.panelWidth()) {
      case 'auto':
        return this._getFieldWidth();
      case 'hug':
        return '';
      default:
        return this.panelWidth();
    }
  }

  private _getFieldWidth() {
    return this._elementRef.nativeElement.getBoundingClientRect().width;
  }

  /** Drops current option subscriptions and IDs and resets from scratch. */
  private _resetOptions(): void {
    const changedOrDestroyed = merge(this.options.changes, this.destroyed$);

    this.optionSelectionChanges
      .pipe(takeUntil(changedOrDestroyed))
      .subscribe((event) => {
        if (event.clear) {
          this.clear();
        } else {
          this._onSelect(event.source, event.isUserInput);
        }

        if (event.isUserInput && !this.multiple && this.panelOpen) {
          this.close();
        }
      });
  }

  public clear(): void {
    this.selectionModel.clear();
    this.propagateValueChange(this._clearValue);
  }

  protected propagateValueChange(value: TValue): void {
    this.value = value;
    this.valueChange.emit(value);
    this._onChange(value);
    this.cd.markForCheck();
  }

  /** Invoked when an option is clicked. */
  protected abstract _onSelect(
    option: OptionComponent<TOptionValue>,
    isUserInput: boolean,
  ): void;

  protected abstract _selectOptionsByValue(
    value: TValue,
  ): OptionComponent<TOptionValue>[];

  private _initializeSelection(): void {
    // Defer setting the value in order to avoid the "Expression
    // has changed after it was checked" errors from Angular.
    void Promise.resolve().then(() => {
      if (this.ngControl) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.value = this.ngControl.value || this._clearValue;
      }

      this._setSelectionByValue(this.value!);
    });
  }

  /**
   * Sets the selected option based on a value. If no option can be
   * found with the designated value, the select trigger is cleared.
   *
   * @param value Value that should be selected
   */
  protected _setSelectionByValue(value: TValue): void {
    this.selectionModel.clear();
    this._selectOptionsByValue(value);
    this.cd.markForCheck();
  }

  /** Focuses the select element. */
  focus(options?: FocusOptions): void {
    this._elementRef.nativeElement.focus(options);
  }

  /**
   * @private
   */
  setDescribedByIds(ids: string[]): void {
    if (ids.length) {
      this._elementRef.nativeElement.setAttribute(
        'aria-describedby',
        ids.join(' '),
      );
    } else {
      this._elementRef.nativeElement.removeAttribute('aria-describedby');
    }
  }

  /**
   * @private
   */
  onContainerClick(): void {
    this.focus();
    this.open();
  }
}
