import { InjectionToken, inject } from '@angular/core';
import { FMNTS_COMPONENT_ICONS } from '@fmnts/components/core';
import { TranslationId } from '@fmnts/i18n';
import { IconDefinition } from '@fortawesome/angular-fontawesome';

export interface FmntsSelectIcons {
  readonly search: IconDefinition;
  /**
   * Icon to indicate select dropdown visibiltiy
   * The caret is rotated using animations
   */
  readonly caret: IconDefinition;
}

export const FMNTS_SELECT_ICONS = new InjectionToken<FmntsSelectIcons>(
  '@fmnts/components/select/select-icons',
  {
    factory: (cmpIcons = inject(FMNTS_COMPONENT_ICONS)): FmntsSelectIcons => ({
      caret: cmpIcons.caret,
      search: cmpIcons.search,
    }),
  },
);

export interface FmntsSelectI18n {
  /** Label for the group of selected options. */
  readonly selected: TranslationId;
}

export const FMNTS_SELECT_I18N = new InjectionToken<FmntsSelectI18n>(
  '@fmnts/components/select/i18n',
  {
    factory: (): FmntsSelectI18n => ({
      selected: 'select_group_selected',
    }),
  },
);
