import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { formDataAdapter, PagedApiResponse } from '@fmnts/api';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import * as P from 'effect/Predicate';
import { Observable } from 'rxjs';
import * as Api from './api-model';

@Injectable({ providedIn: 'root' })
export class LicenceApi {
  private readonly http = inject(HttpClient);
  private readonly apiHelper = inject(ApiRequestHelper);
  private readonly apiConfig = inject(ApiConfigService);

  static readonly rootUrl = '/v2/territory/licences';

  create(dto: Api.CreateLicenceDto): Observable<Api.Licence> {
    const url = this.apiConfig.buildCockpitApiUrl([LicenceApi.rootUrl]);

    const body = _adaptDtoForCreate(dto);
    return this.http.post<Api.Licence>(url, body);
  }

  list(
    query: Api.TerritoryLicenceListQuery,
  ): Observable<PagedApiResponse<Api.Licence>> {
    const url = this.apiConfig.buildCockpitApiUrl([LicenceApi.rootUrl]);
    const params = this.apiHelper.makeQueryParams(query);

    return this.http.get<PagedApiResponse<Api.Licence>>(url, { params });
  }

  delete(id: Api.Licence['id']): Observable<void> {
    const url = this.apiConfig.buildCockpitApiUrl([LicenceApi.rootUrl, id]);
    return this.http.delete<void>(url);
  }
}

function _adaptDtoForCreate(
  dto: Api.CreateLicenceDto,
): Api.CreateLicenceDto | FormData {
  const { document, ...rest } = dto;

  if (P.isNullable(document)) {
    return dto;
  }

  return formDataAdapter.appendBlob(
    formDataAdapter.adaptRecord(rest),
    'document',
    document,
  );
}
