import { InjectionToken } from '@angular/core';
import { IconDefinition } from '@fortawesome/angular-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ReadonlyRecord } from 'effect/Record';
import { FmntsCheckboxState } from './checkbox.model';

export interface CheckboxIcons {
  readonly state: Partial<ReadonlyRecord<FmntsCheckboxState, IconDefinition>>;
}

/** DI tokens for checkbox icons. */
export const FMNTS_CHECKBOX_ICONS = new InjectionToken<CheckboxIcons>(
  '@fmnts/components/checkbox/icons',
  {
    factory: (): CheckboxIcons => ({
      state: {
        checked: faCheck,
      },
    }),
  },
);
