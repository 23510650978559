import { NgModule } from '@angular/core';
import {
  FmntsTextInputPrefixDirective,
  FmntsTextInputSuffixDirective,
} from '@fmnts/components/core';
import { MultiSelectComponent } from './multi-select.component';
import { OptionComponent } from './option.component';
import { SelectComponent } from './select.component';

@NgModule({
  exports: [
    SelectComponent,
    MultiSelectComponent,
    OptionComponent,
    FmntsTextInputPrefixDirective,
    FmntsTextInputSuffixDirective,
  ],
  imports: [
    SelectComponent,
    MultiSelectComponent,
    OptionComponent,
    FmntsTextInputPrefixDirective,
    FmntsTextInputSuffixDirective,
  ],
})
export class FmntsSelectModule {}
