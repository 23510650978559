import { NgModule } from '@angular/core';
import {
  FmntsTextInputPrefixDirective,
  FmntsTextInputSuffixDirective,
} from '@fmnts/components/core';
import { FmntsFormFieldModule } from '@fmnts/components/form-field';
import { FmntsInputDirective } from './input.directive';
import { FmntsTextFieldComponent } from './text-input.component';

@NgModule({
  exports: [
    FmntsInputDirective,
    FmntsTextInputPrefixDirective,
    FmntsTextInputSuffixDirective,
    FmntsTextFieldComponent,
    FmntsFormFieldModule,
  ],
  imports: [
    FmntsFormFieldModule,
    FmntsInputDirective,
    FmntsTextInputPrefixDirective,
    FmntsTextInputSuffixDirective,
    FmntsTextFieldComponent,
  ],
})
export class FmntsInputModule {}
