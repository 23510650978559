import { InjectionToken } from '@angular/core';
import { MatomoTitleStream, MatomoUserStream } from './matomo.model';

export const SHARED_VENDORS_MATOMO_TITLE_STREAM =
  new InjectionToken<MatomoTitleStream>(
    '@formunauts/shared/vendors/matomo/title-stream',
  );

export const SHARED_VENDORS_MATOMO_USER_STREAM =
  new InjectionToken<MatomoUserStream>(
    '@formunauts/shared/vendors/matomo/user-stream',
  );
