import { inject, makeEnvironmentProviders } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { TitleService } from '@fmnts/common/browser';
import { UserStore } from '@formunauts/shared/user/data-access';
import {
  provideSharedMatomoInterop,
  withTitleTracking,
  withUserTracking,
} from '@formunauts/shared/vendors/matomo/data-access';
import * as O from 'effect/Option';
import {
  MatomoConfiguration,
  provideMatomo,
  withRouter,
} from 'ngx-matomo-client';
import { map } from 'rxjs';
import { environment } from '../../environments/environment';
import { MatomoEnvConfig } from '../../environments/environment.type';

export function provideAppAnalytics() {
  return makeEnvironmentProviders([
    provideMatomo(
      () => _normalizeMatomoConfig(O.fromNullable(environment.matomo)),
      withRouter({
        // Exclude public facing routes from tracking.
        exclude: [/\/verify\/.+/],
      }),
    ),
    provideSharedMatomoInterop(
      withTitleTracking((title = inject(TitleService)) =>
        toObservable(title.title),
      ),
      withUserTracking((user = inject(UserStore)) =>
        toObservable(user.username).pipe(
          map(O.map((username) => ({ id: username }))),
        ),
      ),
    ),
  ]);
}

function _normalizeMatomoConfig(
  env: O.Option<MatomoEnvConfig>,
): MatomoConfiguration {
  const fromEnv = O.map(env, _getFromEnv);
  if (O.isSome(fromEnv)) {
    return fromEnv.value;
  }

  return {
    disabled: true,
    siteId: 'ignore',
    trackerUrl: 'ignore',
  };
}

function _getFromEnv(env: MatomoEnvConfig): MatomoConfiguration {
  return {
    disabled: env.enabled !== true,
    siteId: env.siteId,
    trackerUrl: env.trackerUrl,
    acceptDoNotTrack: true,
    enableLinkTracking: true,
    requireConsent: 'none',
  };
}
