/* eslint-disable @angular-eslint/no-host-metadata-property */

import {
  Attribute,
  Component,
  ElementRef,
  HostBinding,
  InjectionToken,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';

/**
 * Injection token that can be used to reference instances of `FmntsFormErrorDirective`.
 * It serves as alternative token to the actual `FmntsFormErrorDirective` class which
 * could cause unnecessary retention of the class and its directive metadata.
 */
export const FMNTS_FORM_ERROR = new InjectionToken<FmntsFormErrorComponent>(
  '@fmnts.components.form-field.error',
);

/** Single error message to be shown underneath the form-field. */
@Component({
  selector: 'fmnts-form-error, [fmntsFormError]',
  host: {
    'aria-atomic': 'true',
  },
  providers: [
    { provide: FMNTS_FORM_ERROR, useExisting: FmntsFormErrorComponent },
  ],
  styleUrl: './form-error.component.scss',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: '<ng-content/>',
})
export class FmntsFormErrorComponent {
  private readonly componentClass = 'fmnts-form-error';

  @HostBinding('class') get hostClasses(): string {
    return classnames([this.componentClass]);
  }

  constructor(
    @Attribute('aria-live') ariaLive: string,
    elementRef: ElementRef<HTMLElement>,
  ) {
    // If no aria-live value is set add 'polite' as a default. This is preferred over setting
    // role='alert' so that screen readers do not interrupt the current task to read this aloud.
    if (!ariaLive) {
      elementRef.nativeElement.setAttribute('aria-live', 'polite');
    }
  }
}
