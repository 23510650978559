import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

let nextUniqueId = 0;

/**
 * Hints to be shown within a `fmnts-form-field`.
 */
@Component({
  selector: 'fmnts-hint',
  template: '<ng-content />',
  standalone: true,
  styleUrl: './hint.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FmntsHintComponent {
  @HostBinding('class.fmnts-form-hint')
  protected readonly componentClass = 'fmnts-form-hint';

  /** Unique ID for the hint. Used for the aria-describedby on the form field control. */
  @HostBinding('id')
  @Input()
  id = `fmnts-hint-${nextUniqueId++}`;
}
