import { Version } from '@angular/core';
import { LogLevel } from '@fmnts/common/log';
import { IAppEnvironment } from './environment.type';

export const environment: IAppEnvironment = {
  production: true,
  name: 'prod',
  version: new Version('15.13.0'),

  log: {
    console: { level: LogLevel.Warn },
    idb: {
      level: LogLevel.Debug,
      driver: { maxEntries: 1000 },
    },
  },
  matomo: {
    enabled: true,
    siteId: 2,
    trackerUrl: 'https://formunauts.matomo.cloud/',
  },
  zendesk: {
    accountUrl: 'formunauts.zendesk.com',
  },
};
