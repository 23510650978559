<div #formControl class="form-control" (click)="toggleInput()">
  <fa-icon [icon]="iconCalendar" class="text-icon-neutral-secondary" />

  <span class="value">{{ value | dateRange: localeId() }}</span>

  <span
    class="toggle text-icon-neutral-secondary"
    [class.rotate-180]="showInput"
  >
    <fa-icon [icon]="iconOpen" />
  </span>
</div>

<div #dropdown class="date-range-picker-selector" [class.!hidden]="!showInput">
  @if (allowDeselect && fromDate && toDate) {
    <div class="deselect" (click)="input = null; toggleInput(false)">
      {{ 'clear_filter' | translate }}
    </div>
  }

  <div class="date-range-picker-row">
    <div class="ranges">
      @for (range of ranges(); track range) {
        <div class="range" (click)="updateFromRange(range)">
          {{ range.label | translate }}
        </div>
      }
    </div>

    <shared-calendar-picker
      [selectedFromDate]="fromDate"
      [selectedToDate]="toDate"
      [firstPossibleDate]="minDate()"
      [lastPossibleDate]="maxDate()"
      [range]="true"
      (selectedRangeChanged)="input = $event; toggleInput(false)"
    />
  </div>
</div>
