import {
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { ProviderFeature, providerFeature } from '@fmnts/common';
import * as Fn from 'effect/Function';
import { MatomoInteropService } from './matomo-interop.service';
import {
  SHARED_VENDORS_MATOMO_TITLE_STREAM,
  SHARED_VENDORS_MATOMO_USER_STREAM,
} from './matomo-interop.tokens';
import { MatomoTitleStream, MatomoUserStream } from './matomo.model';

export enum MatomoInteropFeatureKind {
  Title,
  User,
}

export type MatomoInteropFeature<TKind extends MatomoInteropFeatureKind> =
  ProviderFeature<TKind>;

const { make: makeMatomoInteropFeature } =
  providerFeature<MatomoInteropFeatureKind>();

/**
 * Setup Matomo interop by using features to provide tracking
 * for relevant parts of the application.
 */
export function provideSharedMatomoInterop(
  ...features: MatomoInteropFeature<MatomoInteropFeatureKind>[]
): EnvironmentProviders {
  return makeEnvironmentProviders([
    MatomoInteropService,
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue() {
        inject(MatomoInteropService);
      },
    },
    ...features.flatMap((f) => f.providers),
  ]);
}

/**
 * Sets up tracking the page title with Matomo tracker.
 */
export function withTitleTracking(
  titleStreamFactory: Fn.LazyArg<MatomoTitleStream>,
): MatomoInteropFeature<MatomoInteropFeatureKind.Title> {
  return makeMatomoInteropFeature(MatomoInteropFeatureKind.Title, [
    {
      provide: SHARED_VENDORS_MATOMO_TITLE_STREAM,
      useFactory: titleStreamFactory,
    },
  ]);
}

/**
 * Set up user tracking.
 */
export function withUserTracking(
  userStreamFactory: Fn.LazyArg<MatomoUserStream>,
): MatomoInteropFeature<MatomoInteropFeatureKind.User> {
  return makeMatomoInteropFeature(MatomoInteropFeatureKind.User, [
    {
      provide: SHARED_VENDORS_MATOMO_USER_STREAM,
      useFactory: userStreamFactory,
    },
  ]);
}
