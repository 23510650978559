import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';

export type ButtonGroupType = 'normal' | 'fluid';

/**
 * Displays a group of buttons.
 */
@Component({
  selector: `fmnts-button-group, [fmnts-button-group]`,
  template: `<ng-content />`,
  styleUrls: ['button-group.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'fmnts-button-group',
    '[class.fmnts-button-group--fluid]': 'type() === "fluid"',
  },
})
export class ButtonGroupComponent {
  /**
   * The display type of the button group.
   */
  readonly type = input<ButtonGroupType>('normal');
}
