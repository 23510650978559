import { Directive } from '@angular/core';

/**
 * Use on a prefix item for input fields.
 */
@Directive({
  selector: '[fmntsFieldPrefix]',
  standalone: true,
  host: {
    class: 'fmnts-text-input-prefix-item',
  },
})
export class FmntsTextInputPrefixDirective {}

/**
 * Use on a suffix item for input fields.
 */
@Directive({
  selector: '[fmntsFieldSuffix]',
  standalone: true,
  host: {
    class: 'fmnts-text-input-suffix-item',
  },
})
export class FmntsTextInputSuffixDirective {}
