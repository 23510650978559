import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  HostBinding,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { FmntsLabelComponent } from '@fmnts/components/form-field';
import { FmntsInputDirective } from './input.directive';

type TextFieldAppearance = 'filled' | 'stroked';
type TextFieldShape = 'rounded' | 'pill';

/**
 * Renders a text field component.
 *
 * When using shape pill, consider not using a visible label inside.
 */
@Component({
  selector: 'fmnts-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FmntsTextFieldComponent {
  @HostBinding('class.fmnts-text-input') protected readonly componentClass =
    'fmnts-text-input';

  protected readonly _label = contentChild(FmntsLabelComponent, {
    descendants: true,
  });

  protected readonly _input = contentChild(FmntsInputDirective, {
    descendants: true,
  });

  /**
   * Appearance of the text field.
   */
  readonly appearance = input<TextFieldAppearance>('stroked');

  /**
   * Shape of the text field.
   */
  readonly shape = input<TextFieldShape>('rounded');

  /**
   * Uses `h-fit` instead of default `h-interaction`
   * e.g. for Textarea or input fields with a label
   */
  readonly fitToHeight = computed(
    () => this._label() || this._input()?.isTextArea,
  );

  @HostBinding('class')
  protected get hostClasses(): string {
    const prefix = this.componentClass;
    return classnames([
      `${prefix}--${this.appearance()}`,
      `${prefix}--${this.shape()}`,
      this._label() && `${prefix}--has-label`,
      this.fitToHeight() && `${prefix}--fit-to-height`,
    ]);
  }
}
