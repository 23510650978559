import * as Brand from 'effect/Brand';
import * as Data from 'effect/Data';
import * as Equivalence from 'effect/Equivalence';

export enum Feature {
  Gamification = 'gamification',
  JobReferralLeaderboard = 'job_referral_leaderboard',
  LocationTracking = 'location_tracking',
  LocationModule = 'location_module',
  FundraiserQuizzes = 'fundraiser_quizzes',
  Marketplace = 'marketplace',
  MultiCampaignSetup = 'multi_campaign_setup',
  Launcher = 'launcher',
}
export type Features = `${Feature}`;

export type CustomerId = (number | string) & Brand.Brand<'CustomerId'>;
export const CustomerId = Brand.nominal<CustomerId>();

export interface CustomerRef {
  readonly _tag: 'CustomerRef';
  readonly id: CustomerId;
  readonly name: string;
}

export const CustomerRef = Data.tagged<CustomerRef>('CustomerRef');

export interface Customer {
  id: CustomerId;
  currency: string;
  currencyCode: string;
  locale: string;
  name: string;
  pointsLabel: string;
  slug: string;
  features: ReadonlyArray<Features>;
}
export const Customer = Data.case<Customer>();

/** Compares customer entities for equivalence. */
export const equivalence = Equivalence.mapInput(
  Equivalence.string,
  ({ id }: CustomerRef | Customer) => `${id}`,
);

/**
 * Maps a {@link customer} to a customer reference.
 */
export const customerToCustomerRef = (customer: Customer): CustomerRef =>
  CustomerRef({
    id: customer.id,
    name: customer.name,
  });
