import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewEncapsulation,
  booleanAttribute,
  computed,
  inject,
  model,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { ChangeCallback, TouchedCallback } from '@fmnts/common/forms';
import { htmlIdMaker } from '@fmnts/components/core';
import {
  AbstractFormFieldControl,
  FmntsFormFieldControl,
} from '@fmnts/components/form-field';
import * as Fn from 'effect/Function';
import * as P from 'effect/Predicate';
import * as Checkbox from './checkbox.model';
import { PseudoCheckboxComponent } from './pseudo-checkbox.component';

const checkboxIds = htmlIdMaker('fmnts-checkbox');

/**
 * Displays a checkbox input.
 */
@Component({
  selector: 'fmnts-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PseudoCheckboxComponent],
  host: {
    class: 'fmnts-checkbox',
    '[class.fmnts-checkbox--checked]': 'checked()',
    '[class.fmnts-checkbox--disabled]': 'disabled',
    '[attr.aria-disabled]': 'disabled',
    '[attr.aria-checked]': 'checked()',
  },
  providers: [
    { provide: FmntsFormFieldControl, useExisting: CheckboxComponent },
  ],
})
export class CheckboxComponent
  extends AbstractFormFieldControl<boolean>
  implements ControlValueAccessor
{
  protected readonly _uid = checkboxIds.next().value;
  override controlType = 'checkbox';

  protected readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  override readonly ngControl = inject(NgControl, {
    optional: true,
    self: true,
  });

  /** Whether the checkbox is checked. */
  readonly checked = model<boolean>(false, {});
  protected readonly checkboxState = computed<Checkbox.FmntsCheckboxState>(() =>
    Checkbox.fromBoolean(this.checked()),
  );

  /** Disables the checkbox. */
  @Input({ transform: booleanAttribute })
  disabled = false;

  @Input()
  get id(): string {
    return this._id();
  }
  set id(value: string) {
    this._id.set(value || this._uid);
  }
  protected readonly _id = signal(this._uid);

  /** Whether the control is required. */
  @Input({ transform: booleanAttribute })
  get required(): boolean {
    return (
      this._required() ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }
  set required(value: boolean) {
    this._required.set(value);
    this.stateChanges.next();
  }
  private readonly _required = signal<boolean | undefined>(undefined);

  get value(): boolean {
    return this.checked();
  }

  override get empty(): boolean {
    return false;
  }

  protected _onChange: ChangeCallback<boolean | null> = Fn.constVoid;
  protected _onTouched: TouchedCallback = Fn.constVoid;

  /**
   * Used to ensure that when used in a form-field, that the state
   * isn't toggled twice, on click and container click
   */
  private _changeHandled = false;

  constructor() {
    super();

    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  @HostListener('click')
  onClick(): void {
    this.toggleState();
    this._changeHandled = true;
  }

  override onContainerClick(): void {
    if (!this._changeHandled) {
      this.toggleState();
    }
    this._changeHandled = false;
  }

  override setDescribedByIds(ids: string[]): void {
    if (ids.length) {
      this._elementRef.nativeElement.setAttribute(
        'aria-describedby',
        ids.join(' '),
      );
    } else {
      this._elementRef.nativeElement.removeAttribute('aria-describedby');
    }
  }

  writeValue(value: unknown): void {
    if (!P.isBoolean(value)) {
      // emit warning?
    }

    this.checked.set(P.isTruthy(value));
  }

  registerOnChange(fn: ChangeCallback): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: TouchedCallback): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  private toggleState(): void {
    if (this.disabled) {
      return;
    }

    this._updateValue(!this.checked());
    this._onTouched();
  }

  private _updateValue(checked: boolean): void {
    this.checked.set(checked);
    this._onChange(checked);
  }
}
