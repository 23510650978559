import { InjectionToken } from '@angular/core';
import { IconDefinition } from '@fortawesome/angular-fontawesome';
import {
  faChevronDown,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';

export interface FmntsComponentIcons {
  /** Icon to indicate select dropdown visibiltiy. */
  readonly caret: IconDefinition;
  readonly search: IconDefinition;
}

/**
 * DI token for common icons used in components.
 */
export const FMNTS_COMPONENT_ICONS = new InjectionToken<FmntsComponentIcons>(
  '@fmnts/components/core/icons',
  {
    factory: (): FmntsComponentIcons => ({
      search: faMagnifyingGlass,
      caret: faChevronDown,
    }),
  },
);
