<div
  class="fmnts-select-input"
  (click)="toggleInput()"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  data-testid="multi-select-input"
>
  <span class="fmnts-text-input__prefix">
    <ng-content select="[fmntsFieldPrefix]" />
  </span>
  <span class="fmnts-text-input__infix">
    @if (empty || valueDisplay() === 'label') {
      <ng-content select="fmnts-label,label[fmnts-select-label]" />
      <div
        class="fmnts-select-input__placeholder"
        data-testid="multi-select-input-placeholder"
      >
        {{ placeholder || '&ZeroWidthSpace;' }}
      </div>
    } @else {
      <div
        class="fmnts-select-input__value"
        data-testid="multi-select-input-value"
      >
        {{ formattedValues }}
      </div>
    }
  </span>

  <span class="fmnts-text-input__suffix">
    <ng-content select="[fmntsFieldSuffix]" />
    @if (showCounter() && !empty) {
      <span
        class="bg-brand-tertiary text-brand rounded-full size-4 inline-flex items-center justify-center shrink-0 grow-0 typo-body typo-body-xs leading-none"
      >
        {{ count }}
      </span>
    }
    <span
      class="fmnts-select-dropdown-toggle"
      [@fmntsIconRotateZ]="panelOpen ? 180 : 0"
    >
      <fa-icon [icon]="icons.caret" />
    </span>
  </span>
</div>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayDisableClose]="true"
  [cdkConnectedOverlayPanelClass]="_overlayPanelClass"
  [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayWidth]="_overlayWidth()"
  [cdkConnectedOverlayMinWidth]="_fieldWidth()"
  (backdropClick)="close()"
  (overlayOutsideClick)="close()"
  (overlayKeydown)="_handleOverlayKeydown($event)"
  [cdkConnectedOverlayOpen]="_panelOpen()"
  (detach)="close()"
>
  <div
    [class]="'fmnts-select-dropdown ' + panelClass()"
    data-testid="multi-select-dropdown"
  >
    @if (showSearchField()) {
      <div class="fmnts-select-dropdown__typeahead">
        <fmnts-text-input data-testid="dropdown-multi-select-type-ahead">
          <fa-icon fmntsFieldPrefix [icon]="icons.search" />
          <input
            #typeAhead
            fmnts-input
            type="text"
            [formControl]="typeAheadControl"
            placeholder="{{ searchInputPlaceholder() }}"
          />
        </fmnts-text-input>
      </div>
    }

    <div
      class="fmnts-select-options"
      [class.fmnts-select-options--selected-on-top]="pullSelectedOnTop()"
      data-testid="dropdown-multi-select-options"
    >
      <!-- Project option for deselecting -->
      @if (allowDeselect() && !empty) {
        <ng-content select="fmnts-select-option[deselect]" />
      }

      @if (pullSelectedOnTop() && !empty) {
        <div class="fmnts-select-options__group-name">
          {{ i18n.selected | translate }}
        </div>
        @if (hasTypeAheadResult) {
          <div class="fmnts-select-options__group-divider"></div>
        }
      }

      <!-- Project options with a value -->
      <ng-content />

      <!-- Project option to show no-results info -->
      @if (!hasTypeAheadResult) {
        <ng-content select="fmnts-select-option[noAvailableOptions]" />
      }
      @if (isLoadingOptions()) {
        <ng-content select="[isLoadingOptions]" />
      }
    </div>
  </div>
</ng-template>
