/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/no-input-rename */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { getSupportedInputTypes, Platform } from '@angular/cdk/platform';
import {
  Directive,
  DoCheck,
  ElementRef,
  HostListener,
  inject,
  Input,
  NgZone,
  signal,
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import {
  AbstractFormFieldControl,
  FmntsFormFieldControl,
} from '@fmnts/components/form-field';
import { fromEvent, takeUntil } from 'rxjs';
import {
  FMNTS_INPUT_VALUE_ACCESSOR,
  InputValueAccessor,
} from './input-value-accessor';

let nextUniqueId = 0;

/**
 * Input for `fmnts-form-field`.
 */
@Directive({
  selector: 'input[fmnts-input], textarea[fmnts-input]',
  standalone: true,
  host: {
    class: 'fmnts-input',
    '[class.fmnts-input-form-field-textarea-control]': '_isTextarea',
    // Only mark the input as invalid for assistive technology if it has a value since the
    // state usually overlaps with `aria-required` when the input is empty and can be redundant.
    '[attr.aria-invalid]': '(empty && required) ? null : errorState',
    '[attr.aria-required]': 'required',
    // Native input properties that are overwritten by Angular inputs need to be synced with
    // the native input element. Otherwise property bindings for those don't work.
    '[id]': 'id',
    '[disabled]': '_disabled()',
    '[required]': 'required',
    '[attr.id]': 'id',
    '[attr.name]': 'name || null',
    '[attr.readonly]': 'readonly || null',
  },
  providers: [
    { provide: FmntsFormFieldControl, useExisting: FmntsInputDirective },
  ],
})
export class FmntsInputDirective
  extends AbstractFormFieldControl<unknown>
  implements DoCheck
{
  override readonly controlType = 'fmnts-input';
  override readonly ngControl = inject(NgControl, {
    optional: true,
    self: true,
  });

  @Input()
  get disabled(): boolean {
    return this._disabled();
  }
  set disabled(value: BooleanInput) {
    this._disabled.set(coerceBooleanProperty(value));

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  protected readonly _disabled = signal(false);

  @Input()
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value || this._uid;
  }
  protected _id!: string;

  /**
   * Name of the input.
   */
  @Input() name = '';

  @Input()
  get required(): boolean {
    return (
      this._required ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  protected _required: boolean | undefined;

  /** Input type of the element. */
  @Input()
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value || 'text';

    // When using Angular inputs, developers are no longer able to set the properties on the native
    // input element. To ensure that bindings for `type` work, we need to sync the setter
    // with the native property. Textarea elements don't support the type property or attribute.
    if (!this._isTextarea && getSupportedInputTypes().has(this._type)) {
      (this._elementRef.nativeElement as HTMLInputElement).type = this._type;
    }
  }
  protected _type = 'text';

  @Input()
  get value(): unknown {
    return this._inputValueAccessor.value;
  }
  set value(value: unknown) {
    if (value !== this.value) {
      this._inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }

  protected _uid = `fmnts-input-${nextUniqueId++}`;
  protected _previousNativeValue: any;
  protected readonly _elementRef =
    inject<ElementRef<HTMLInputElement | HTMLTextAreaElement>>(ElementRef);
  private readonly _inputValueAccessor: InputValueAccessor<unknown> =
    inject(FMNTS_INPUT_VALUE_ACCESSOR, { self: true, optional: true }) ||
    this._elementRef.nativeElement;
  private _previousPlaceholder: string | null = null;

  /** Whether the component is a textarea. */
  get isTextArea(): boolean {
    return this._isTextarea;
  }
  protected readonly _isTextarea: boolean;

  protected _neverEmptyInputTypes = [
    'date',
    'datetime',
    'datetime-local',
    'month',
    'time',
    'week',
  ].filter((t) => getSupportedInputTypes().has(t));

  constructor() {
    super();

    const element = this._elementRef.nativeElement;
    const nodeName = element.nodeName.toLowerCase();

    this._previousNativeValue = this.value;

    // Force setter to be called in case id was not specified.
    // eslint-disable-next-line no-self-assign
    this.id = this.id;

    // On some versions of iOS the caret gets stuck in the wrong place when holding down the delete
    // key. In order to get around this we need to "jiggle" the caret loose. Since this bug only
    // exists on iOS, we only bother to install the listener on iOS.
    if (inject(Platform).IOS) {
      inject(NgZone).runOutsideAngular(() => {
        fromEvent(element, 'keyup')
          .pipe(takeUntil(this.destroyed$))
          .subscribe(this._iOSKeyupListener);
      });
    }

    this._isTextarea = nodeName === 'textarea';
  }

  override ngDoCheck(): void {
    super.ngDoCheck();

    // We need to dirty-check the native element's value, because there are some cases where
    // we won't be notified when it changes (e.g. the consumer isn't using forms or they're
    // updating the value using `emitEvent: false`).
    this._dirtyCheckNativeValue();

    // We need to dirty-check and set the placeholder attribute ourselves, because whether it's
    // present or not depends on a query which is prone to "changed after checked" errors.
    this._dirtyCheckPlaceholder();
  }

  @HostListener('focus')
  protected _onFocus(): void {
    this._setFocusedState(true);
  }
  @HostListener('blur')
  protected _onBlur(): void {
    this._setFocusedState(false);
  }

  @HostListener('input')
  protected _onInput(): void {
    // This is a noop function and is used to let Angular know whenever the value changes.
    // Angular will run a new change detection each time the `input` event has been dispatched.
    // It's necessary that Angular recognizes the value change, because when floatingLabel
    // is set to false and Angular forms aren't used, the placeholder won't recognize the
    // value changes and will not disappear.
    // Listening to the input event wouldn't be necessary when the input is using the
    // FormsModule or ReactiveFormsModule, because Angular forms also listens to input events.
  }

  /** Focuses the input. */
  focus(options?: FocusOptions): void {
    this._elementRef.nativeElement.focus(options);
  }

  get empty(): boolean {
    return (
      !this._isNeverEmpty() &&
      !this._elementRef.nativeElement.value &&
      !this._isBadInput()
    );
  }

  setDescribedByIds(ids: string[]): void {
    if (ids.length) {
      this._elementRef.nativeElement.setAttribute(
        'aria-describedby',
        ids.join(' '),
      );
    } else {
      this._elementRef.nativeElement.removeAttribute('aria-describedby');
    }
  }

  onContainerClick(): void {
    // Do not re-focus the input element if the element is already focused. Otherwise it can happen
    // that someone clicks on a time input and the cursor resets to the "hours" field while the
    // "minutes" field was actually clicked. See: https://github.com/angular/components/issues/12849
    if (!this.focused) {
      this.focus();
    }
  }

  /** Gets the current placeholder of the form field. */
  protected _getPlaceholder(): string | null {
    return this.placeholder || null;
  }

  /** Checks whether the input type is one of the types that are never empty. */
  protected _isNeverEmpty(): boolean {
    return this._neverEmptyInputTypes.indexOf(this._type) > -1;
  }

  /** Checks whether the input is invalid based on the native validation. */
  protected _isBadInput(): boolean {
    // The `validity` property won't be present on platform-server.
    const validity = this._elementRef.nativeElement.validity;
    return validity?.badInput;
  }

  /** Does some manual dirty checking on the native input `value` property. */
  protected _dirtyCheckNativeValue(): void {
    const newValue = this._elementRef.nativeElement.value;

    if (this._previousNativeValue !== newValue) {
      this._previousNativeValue = newValue;
      this.stateChanges.next();
    }
  }

  /** Does some manual dirty checking on the native input `placeholder` attribute. */
  private _dirtyCheckPlaceholder() {
    const placeholder = this._getPlaceholder();
    if (placeholder !== this._previousPlaceholder) {
      const element = this._elementRef.nativeElement;
      this._previousPlaceholder = placeholder;
      if (placeholder) {
        element.setAttribute('placeholder', placeholder);
      } else {
        element.removeAttribute('placeholder');
      }
    }
  }

  private _iOSKeyupListener = (event: Event): void => {
    const el = event.target as HTMLInputElement;

    // Note: We specifically check for 0, rather than `!el.selectionStart`, because the two
    // indicate different things. If the value is 0, it means that the caret is at the start
    // of the input, whereas a value of `null` means that the input doesn't support
    // manipulating the selection range. Inputs that don't support setting the selection range
    // will throw an error so we want to avoid calling `setSelectionRange` on them. See:
    // https://html.spec.whatwg.org/multipage/input.html#do-not-apply
    if (!el.value && el.selectionStart === 0 && el.selectionEnd === 0) {
      // Note: Just setting `0, 0` doesn't fix the issue. Setting
      // `1, 1` fixes it for the first time that you type text and
      // then hold delete. Toggling to `1, 1` and then back to
      // `0, 0` seems to completely fix it.
      el.setSelectionRange(1, 1);
      el.setSelectionRange(0, 0);
    }
  };
}
