import {
  ANIMATION_MODULE_TYPE,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  InjectionToken,
  ViewEncapsulation,
  booleanAttribute,
  contentChildren,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { htmlIdMaker } from '@fmnts/components/core';
import { AbstractFormFieldComponent } from './abstract-form-field.component';
import { FmntsFormErrorComponent } from './form-error.component';
import { FmntsHintComponent } from './hint.component';

/**
 * Represents the default options for the form field that can be configured
 * using the `FMNTS_FORM_FIELD_DEFAULT_OPTIONS` injection token.
 */
export interface FmntsFormFieldDefaultOptions {
  /** Whether the required marker should be hidden by default. */
  hideRequiredMarker?: boolean;
}

/**
 * Injection token that can be used to inject an instances of `FmntsFormField`. It serves
 * as alternative token to the actual `FmntsFormField` class which would cause unnecessary
 * retention of the `FmntsFormField` class and its component metadata.
 */
export const FMNTS_FORM_FIELD = new InjectionToken<FmntsFormFieldComponent>(
  '@fmnts.components.form-field.component',
);

/**
 * Injection token that can be used to configure the
 * default options for all form field within an app.
 */
export const FMNTS_FORM_FIELD_DEFAULT_OPTIONS =
  new InjectionToken<FmntsFormFieldDefaultOptions>(
    '@fmnts.components.form-field.default-options',
  );

const labelId = htmlIdMaker('fmnts-form-field-label');
const hintLabelId = htmlIdMaker('fmnts-hint');

/**
 * ***NOTE***:
 * **Do not** use component in production.
 * Only experimental at the moment.
 *
 * Displays a form field.
 *
 * Use this component to provide a consistent form UI and UX.
 */
@Component({
  selector: 'fmnts-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: FMNTS_FORM_FIELD, useExisting: FmntsFormFieldComponent },
  ],
  host: {
    class: 'fmnts-form-field',
    '[class.fmnts-form-field--invalid]': 'control.errorState',
    '[class.fmnts-form-field--disabled]': 'control.disabled',
    '[class.fmnts-form-field--readonly]': 'control.readonly',
    '[class.fmnts-form-field--focused]': 'control.focused',
    '[class.fmnts-form-field--no-animations]':
      '_animationMode === "NoopAnimations"',
    // syncs angular forms classes
    '[class.ng-untouched]': 'shouldForward("untouched")',
    '[class.ng-touched]': 'shouldForward("touched")',
    '[class.ng-pristine]': 'shouldForward("pristine")',
    '[class.ng-dirty]': 'shouldForward("dirty")',
    '[class.ng-valid]': 'shouldForward("valid")',
    '[class.ng-invalid]': 'shouldForward("invalid")',
    '[class.ng-pending]': 'shouldForward("pending")',
  },
})
export class FmntsFormFieldComponent
  extends AbstractFormFieldComponent
  implements AfterContentInit, AfterViewInit
{
  public readonly _elementRef = inject(ElementRef<HTMLElement>);
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  private readonly _destroyRef = inject(DestroyRef);
  public readonly _animationMode = inject(ANIMATION_MODULE_TYPE, {
    optional: true,
  });

  protected readonly _errorChildren = contentChildren(FmntsFormErrorComponent, {
    descendants: true,
  });
  protected readonly _hintChildren = contentChildren(FmntsHintComponent, {
    descendants: true,
  });

  /** Whether the required marker should be hidden. */
  readonly hideRequiredMarker = input(withDefaults().hideRequiredMarker, {
    transform: booleanAttribute,
  });

  // Unique id for the internal form field label.
  readonly _labelId = labelId.next().value;

  // Unique id for the hint label.
  readonly _hintLabelId = hintLabelId.next().value;

  /** State of the fmnts-hint and fmnts-error animations. */
  _subscriptAnimationState = '';

  private readonly _isFocused = signal<boolean | null>(null);

  ngAfterViewInit(): void {
    // Initial focus state sync. This happens rarely, but we want to account for
    // it in case the form field control has "focused" set to true on init.
    this._updateFocusState();
    // Enable animations now. This ensures we don't animate on initial render.
    this._subscriptAnimationState = 'enter';
    // Because the above changes a value used in the template after it was checked, we need
    // to trigger CD or the change might not be reflected if there is no other CD scheduled.
    this._changeDetectorRef.detectChanges();
  }

  ngAfterContentInit(): void {
    this._initializeControl();
  }

  @HostListener('click', ['$event'])
  protected _onClick(ev: MouseEvent): void {
    this.control.onContainerClick(ev);
  }

  /** Determines whether to display hints or errors. */
  protected _getDisplayedMessages(): 'error' | 'hint' {
    return this._errorChildren().length > 0 && this.control.errorState
      ? 'error'
      : 'hint';
  }

  private _updateFocusState() {
    const focused = this._isFocused();
    // Handle the focus by checking if the abstract form field control focused state changes.
    if (this.control.focused && !focused) {
      this._isFocused.set(true);
    } else if (!this.control.focused && (focused || focused === null)) {
      this._isFocused.set(false);
    }
  }

  /** Initializes the registered form field control. */
  private _initializeControl() {
    const control = this.control;

    if (control.controlType) {
      this._elementRef.nativeElement.classList.add(
        `fmnts-form-field-type-${control.controlType}`,
      );
    }

    // Subscribe to changes in the child control state in order to update the form field UI.
    control.stateChanges.subscribe(() => {
      this._updateFocusState();
      this._changeDetectorRef.markForCheck();
    });

    // Run change detection if the value changes.
    if (control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe(() => this._changeDetectorRef.markForCheck());
    }
  }
}

function withDefaults(
  opts = inject(FMNTS_FORM_FIELD_DEFAULT_OPTIONS, {
    optional: true,
  }),
): FmntsFormFieldDefaultOptions {
  return (
    opts ?? {
      hideRequiredMarker: false,
    }
  );
}
