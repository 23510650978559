<div class="error-page">
  <fmnts-empty-state>
    <fmnts-empty-state-visual>
      <fa-icon [icon]="_iconNotFound" size="lg"></fa-icon>
    </fmnts-empty-state-visual>
    <fmnts-empty-state-title>
      {{ 'page_not_found_title' | translate }}
    </fmnts-empty-state-title>
    <fmnts-empty-state-description>
      {{ 'page_not_found_description' | translate }}
    </fmnts-empty-state-description>

    <fmnts-button-group class="justify-center">
      <a fmnts-button color="primary" fill="solid" href="/">{{
        'page_not_found_link' | translate
      }}</a>
    </fmnts-button-group>
  </fmnts-empty-state>
</div>
