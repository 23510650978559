import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeCallback, TouchedCallback } from '@fmnts/common/forms';
import { FMNTS_COMPONENT_ICONS } from '@fmnts/components/core';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { I18nModule, LocaleService } from '@fmnts/i18n';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { MomentModule } from 'ngx-moment';
import { CalendarPickerComponent } from '../calendar-picker/calendar-picker.component';

// TODO(architecture): Move to @fmnts/components.
/**
 * Component that displays an input field and allows
 * users to select a single date.
 */
@Component({
  selector: 'shared-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  standalone: true,
  imports: [
    MomentModule,
    FmntsIconsModule,
    I18nModule,
    CalendarPickerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent
  implements ControlValueAccessor, AfterViewChecked
{
  @HostBinding('class') componentClass = 'date-picker';

  @Input() allowDeselect = false;
  @Input() firstPossibleDate: moment.Moment | null = null;
  @Input() lastPossibleDate: moment.Moment | null = null;

  @ViewChild('dropdown', { static: true })
  dropdownElement!: ElementRef<HTMLDivElement>;

  public date: moment.Moment | null | undefined = null;
  public showInput = false;

  protected readonly iconCalendar = faCalendar;
  protected readonly iconOpen = inject(FMNTS_COMPONENT_ICONS).caret;

  protected readonly localeId = inject(LocaleService).localeId;

  private _onChange: ChangeCallback<moment.Moment | null | undefined> =
    () => {};
  public _onTouched: TouchedCallback = () => {};

  @HostListener('document:keydown.escape')
  onKeydownHandler(): void {
    this.toggleInput(false);
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:select-opened', ['$event'])
  clickOutsideHandler($event: MouseEvent | CustomEvent): void {
    if ($event.type === 'select-opened') {
      if ($event.detail !== this) {
        this.toggleInput(false);
      }
    } else if (this.showInput) {
      $event.stopPropagation();
      this.toggleInput(false);
    }
  }

  @HostListener('click', ['$event'])
  clickInsideHandler($event: MouseEvent): void {
    $event.stopPropagation();

    if (this.showInput) {
      document.dispatchEvent(
        new CustomEvent('select-opened', { detail: this }),
      );
    }
  }

  /**
   * represents the internal date data structure that's either null or Moment
   */
  get value(): moment.Moment | null | undefined {
    return this.date;
  }

  set value(value: moment.Moment | null | undefined) {
    this.date = value;
    this._onChange(value);
    this._onTouched();
  }

  set input(date: moment.Moment | null | undefined) {
    this.date = date ? moment(date) : undefined;

    this._onChange(this.date);
  }

  writeValue(value: moment.Moment | null | undefined): void {
    this.input = value;
  }

  registerOnChange(fn: ChangeCallback): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: TouchedCallback): void {
    this._onTouched = fn;
  }

  toggleInput(forceValue: boolean | null = null): void {
    this.showInput = forceValue !== null ? forceValue : !this.showInput;
  }

  ngAfterViewChecked(): void {
    const el = this.dropdownElement.nativeElement;

    if (this.showInput) {
      const bounds = el.getBoundingClientRect();

      if (bounds.left < 0 && document.body.clientWidth > 575) {
        el.style.right = `${bounds.left}px`;
      }
    } else {
      el.style.removeProperty('right');
    }
  }
}
