import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { isNonEmptyString } from '@fmnts/core';
import * as Arr from 'effect/Array';
import * as Fn from 'effect/Function';

/**
 * A service that can be used to get and set the title of a current HTML document.
 */
@Injectable()
export class TitleService {
  private readonly ngTitleService = inject(Title);
  private readonly suffix = signal('Formunauts');
  private readonly _separator = signal(' · ');
  /** Separator that is used to join path segments. */
  readonly separator = this._separator.asReadonly();
  private readonly segments = signal<readonly string[]>([]);

  /** Current page title. */
  readonly title = computed(() => Arr.join(this.segments(), this.separator()));
  /** Full page title including suffix. */
  readonly fullTitle = computed(() =>
    Fn.pipe(
      [this.title(), this.suffix()],
      Arr.filter(isNonEmptyString),
      Arr.join(this.separator()),
    ),
  );

  constructor() {
    effect(() => {
      this.ngTitleService.setTitle(this.fullTitle());
    });
  }

  /**
   * Suffix that should be applied to the title.
   *
   * @param suffix Suffix that should be append when setting the title
   */
  setSuffix(suffix: string = 'Formunauts'): void {
    this.suffix.set(suffix);
  }

  /**
   * Set the title of the current HTML document.
   * The current suffix is append to the title.
   *
   * @param title Title to set on the HTML
   */
  setTitle(title?: string): void {
    this.setTitlePath(title ? [title] : []);
  }

  /**
   * Set the title of the current HTML document by combinig the
   * given `pathSegments` using the default separator and with the
   * configured suffix.
   *
   * @param pathSegments Segments that should be combined for the title
   */
  setTitlePath(pathSegments: readonly string[]): void {
    this.segments.set(Arr.copy(pathSegments));
  }
}
