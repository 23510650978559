import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'fmnts-label',
  template: '<ng-content />',
  styleUrl: './label.component.scss',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class FmntsLabelComponent {
  @HostBinding('class.fmnts-label') protected readonly componentClass =
    'fmnts-label';
}
