/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  DestroyRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  booleanAttribute,
  forwardRef,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { classnames } from '@fmnts/common';
import { ChangeCallback, TouchedCallback } from '@fmnts/common/forms';
import { ThemeColor } from '@fmnts/components';
import { htmlIdMaker } from '@fmnts/components/core';
import {
  AbstractFormFieldControl,
  FmntsFormFieldControl,
} from '@fmnts/components/form-field';
import { Option, none } from '@fmnts/core';
import { AbstractRadioButton } from './base-radio-button.directive';
import {
  FMNTS_RADIO_GROUP,
  FmntsRadioButton,
  FmntsRadioChange,
} from './radio.model';

const radioGroupIds = htmlIdMaker('fmnts-radio-group');

/**
 * Displays a group of radio buttons.
 */
@Directive({
  selector: 'fmnts-radio-group',
  standalone: true,
  providers: [
    { provide: FMNTS_RADIO_GROUP, useExisting: FmntsRadioGroup },
    { provide: FmntsFormFieldControl, useExisting: FmntsRadioGroup },
  ],
  host: {
    role: 'radiogroup',
    '[id]': '_id()',
    '[attr.id]': '_id()',
    // Only mark the input as invalid for assistive technology if it has a value since the
    // state usually overlaps with `aria-required` when the input is empty and can be redundant.
    '[attr.aria-invalid]': '(empty && required) ? null : errorState',
    '[attr.aria-required]': 'required',
  },
})
export class FmntsRadioGroup
  extends AbstractFormFieldControl<unknown>
  implements AfterContentInit, ControlValueAccessor
{
  private readonly _cd = inject(ChangeDetectorRef);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  override readonly ngControl = inject(NgControl, {
    optional: true,
    self: true,
  });

  private readonly _uniqueName = radioGroupIds.next().value;

  @HostBinding('class.fmnts-radio-group')
  override readonly controlType = 'fmnts-radio-group';

  /** Theme color for all of the radio buttons in the group. */
  @Input() color: ThemeColor = 'primary';

  /** Name of the radio button group. All radio buttons inside this group will use this name. */
  @Input()
  get name(): string {
    return this._name();
  }
  set name(value: string) {
    this._name.set(value);
  }
  /**
   * The HTML name attribute applied to radio buttons in this group.
   *
   * @internal Exposed to be used by {@link AbstractRadioButton}.
   */
  readonly _name = signal(this._uniqueName);

  @Input() get id(): string {
    return this._id();
  }
  set id(value: string) {
    this._id.set(value);
  }
  private _id = signal(this._uniqueName);

  /**
   * Value for the radio-group. Should equal the value of the selected radio button if there is
   * a corresponding radio button with a matching value. If there is not such a corresponding
   * radio button, this value persists to be applied in case a new radio button is added with a
   * matching value.
   */
  @Input()
  get value(): unknown {
    return this._value;
  }
  set value(newValue: unknown) {
    if (this._value === newValue) {
      return;
    }

    // Set this before proceeding to ensure no circular loop occurs with selection.
    this._value = newValue;

    this._updateSelectedRadioFromValue();
    this._checkSelectedRadioButton();
  }
  private _value: unknown = none;

  /**
   * The currently selected radio button. If set to a new radio button, the radio group value
   * will be updated to match the new selected button.
   */
  @Input()
  get selected(): Option<FmntsRadioButton<unknown>> {
    return this._selected;
  }
  set selected(selected: Option<FmntsRadioButton<unknown>>) {
    this._selected = selected;
    this.value = selected ? selected.value : null;
    this._checkSelectedRadioButton();
  }
  /** The currently selected radio button. Should match value. */
  private _selected: Option<FmntsRadioButton<unknown>> = null;

  /** Whether the radio group is disabled */
  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled();
  }
  set disabled(value: boolean) {
    this._disabled.set(value);
  }
  /**
   * Whether the radio group is disabled.
   *
   * @internal Exposed to be used by {@link AbstractRadioButton}.
   */
  readonly _disabled = signal(false);

  /** Whether the radio group is required. */
  @Input({ transform: booleanAttribute })
  get required(): boolean {
    return this._required();
  }
  set required(value: boolean) {
    this._required.set(value);
  }
  /**
   * Whether the radio group is required.
   *
   * @internal Exposed to be used by {@link AbstractRadioButton}.
   */
  readonly _required = signal(false);

  /**
   * Event emitted when the group value changes.
   * Change events are only emitted when the value changes due to user interaction with
   * a radio button (the same behavior as `<input type-"radio">`).
   */
  @Output() readonly radioChange = new EventEmitter<
    FmntsRadioChange<unknown>
  >();

  /** Child radio buttons. */
  @ContentChildren(forwardRef(() => FmntsRadioButton), {
    descendants: true,
  })
  _radios?: QueryList<FmntsRadioButton<unknown>>;

  @HostBinding('class')
  get dynamicClasses(): string {
    return classnames([
      this.errorState && `${this.controlType}--invalid`,
      this.empty && `${this.controlType}--empty`,
      this.readonly && `${this.controlType}--readonly`,
      this.color && `${this.controlType}--${this.color}`,
    ]);
  }

  override get empty(): boolean {
    return this._selected === null;
  }

  /** Whether the `value` has been set to its initial value. */
  private _isInitialized = false;

  /**
   * The method to be called in order to update ngModel.
   *
   * @internal Also used by {@link AbstractRadioButton} to propagate changes.
   */
  _onChange: ChangeCallback<unknown> = () => {};

  /**
   * onTouch function registered via registerOnTouch (ControlValueAccessor).
   *
   * @internal Also used by {@link AbstractRadioButton} to propagate changes.
   */
  _onTouched: TouchedCallback = () => {};

  constructor() {
    super();

    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Initialize properties once content children are available.
   * This allows us to propagate relevant attributes to associated buttons.
   */
  ngAfterContentInit(): void {
    // Mark this component as initialized in AfterContentInit because the initial value can
    // possibly be set by NgModel on FmntsRadioGroup, and it is possible that the OnInit of the
    // NgModel occurs *after* the OnInit of the FmntsRadioGroup.
    this._isInitialized = true;

    this._unsetSelectionIfNoLongerAvailable();
  }

  override setDescribedByIds(ids: string[]): void {
    if (ids.length) {
      this._elementRef.nativeElement.setAttribute(
        'aria-describedby',
        ids.join(' '),
      );
    } else {
      this._elementRef.nativeElement.removeAttribute('aria-describedby');
    }
  }
  override onContainerClick(event: MouseEvent): void {}

  /** Ensure that the `checked` property of the selected radio button is up to date. */
  private _checkSelectedRadioButton(): void {
    if (this._selected?.checked === false) {
      this._selected.checked = true;
    }
  }

  /** Updates the `selected` radio button from the internal _value state. */
  private _updateSelectedRadioFromValue(): void {
    // If the value already matches the selected radio, do nothing.
    const isAlreadySelected =
      this._selected !== null && this._selected.value === this._value;

    if (this._radios && !isAlreadySelected) {
      this._selected = null;

      this._radios.forEach((radio) => {
        radio.checked = this.value === radio.value;
        if (radio.checked) {
          this._selected = radio;
        }
      });
    }
  }

  /**
   * Dispatch change event with current selection and group value.
   *
   * @internal Also used by {@link AbstractRadioButton} to propagate changes.
   */
  _emitChangeEvent(): void {
    if (this._isInitialized) {
      this.radioChange.emit(new FmntsRadioChange(this._selected!, this._value));
    }
  }

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: unknown): void {
    this.value = value;
    this._cd.markForCheck();
  }

  /**
   * Registers a callback to be triggered when the model value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: ChangeCallback<unknown>): void {
    this._onChange = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: TouchedCallback): void {
    this._onTouched = fn;
  }

  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   * @param isDisabled Whether the control should be disabled.
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _unsetSelectionIfNoLongerAvailable() {
    // Clear the `selected` button when it's destroyed since the tabindex of the rest of the
    // buttons depends on it. Note that we don't clear the `value`, because the radio button
    // may be swapped out with a similar one and there are some internal apps that depend on
    // that behavior.
    this._radios?.changes
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        if (
          this.selected &&
          !this._radios?.find((radio) => radio === this.selected)
        ) {
          this._selected = null;
        }
      });
  }
}
