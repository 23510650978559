import { InjectionToken } from '@angular/core';
import { TranslationId } from '@fmnts/i18n';
import { IconDefinition } from '@fortawesome/angular-fontawesome';
import {
  faCancel,
  faChevronLeft,
  faChevronRight,
  faCircleQuestion,
  faCloudExclamation,
  faDiamondExclamation,
  faFileDownload,
  faPenToSquare,
  faRotate,
  faSave,
  faTrafficCone,
  faTrash,
  faWifiSlash,
} from '@fortawesome/pro-regular-svg-icons';

interface CommonEmptyState {
  readonly title: TranslationId;
  readonly description: TranslationId;
  readonly icon: IconDefinition;
}
export interface SharedUiCommonEmptyStates {
  /**
   * Use when the user is offline and the app can't connect to the server.
   */
  readonly offline: CommonEmptyState;
  /**
   * Use when a request timed out.
   */
  readonly timeout: CommonEmptyState;
  /**
   * Use when the backend encountered an unexpected error.
   */
  readonly serverUnexpected: CommonEmptyState;
  /**
   * Use when the backend is not accepting requests due to maintainence.
   */
  readonly serverUnavailable: CommonEmptyState;
  /**
   * Use for unexpected errors.
   */
  readonly unexpected: CommonEmptyState;
  /**
   * Use when the resource cannot be found
   */
  readonly notFound: CommonEmptyState;
}

export interface CommonUiAction {
  readonly label: TranslationId;
  readonly icon: IconDefinition;
}
export interface SharedUiCommonActions {
  /** Use for navigating to a previous view. */
  readonly back: CommonUiAction;
  /** Use for canceling an action. */
  readonly cancel: CommonUiAction;
  /** Use for deleting. */
  readonly delete: CommonUiAction;
  /** Use for downloading data. */
  readonly download: CommonUiAction;
  /** Use for editing. */
  readonly edit: CommonUiAction;
  /** Use for navigating to a next view. */
  readonly next: CommonUiAction;
  /** Use for retrying an action that failed. */
  readonly retry: CommonUiAction;
  /** Use for saving. */
  readonly save: CommonUiAction;
}

/**
 * DI token that provides common empty states for shared UI.
 */
export const SHARED_UI_COMMON_EMPTY_STATES =
  new InjectionToken<SharedUiCommonEmptyStates>(
    '@formunauts/shared/ui/common-empty-states',
    {
      providedIn: 'root',
      factory: () => ({
        timeout: {
          title: 'client_timeout_error_title',
          description: 'client_timeout_error_description',
          icon: faWifiSlash,
        },
        offline: {
          title: 'client_offline_error_title',
          description: 'client_offline_error_description',
          icon: faWifiSlash,
        },
        serverUnavailable: {
          title: 'server_unavailable_error_title',
          description: 'server_unavailable_error_description',
          icon: faTrafficCone,
        },
        serverUnexpected: {
          title: 'server_unexpected_error_title',
          description: 'server_unexpected_error_description',
          icon: faCloudExclamation,
        },
        unexpected: {
          title: 'unknown_error',
          description: 'unknown_error_description',
          icon: faDiamondExclamation,
        },
        notFound: {
          title: 'page_not_found_title',
          description: 'page_not_found_description',
          icon: faCircleQuestion,
        },
      }),
    },
  );

/**
 * DI token that provides common i18n actions for shared UI.
 */
export const SHARED_UI_COMMON_ACTIONS =
  new InjectionToken<SharedUiCommonActions>(
    '@formunauts/shared/ui/common-actions',
    {
      providedIn: 'root',
      factory: () => ({
        back: {
          label: 'back',
          icon: faChevronLeft,
        },
        cancel: {
          label: 'cancel',
          icon: faCancel,
        },
        delete: {
          label: 'delete',
          icon: faTrash,
        },
        download: {
          label: 'download',
          icon: faFileDownload,
        },
        edit: {
          label: 'edit',
          icon: faPenToSquare,
        },
        next: {
          label: 'next',
          icon: faChevronRight,
        },
        retry: {
          label: 'retry',
          icon: faRotate,
        },
        save: {
          label: 'save',
          icon: faSave,
        },
      }),
    },
  );
