import { computed, effect, inject, Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import type { CalendarWeek, TimeSpanInput } from '@fmnts/core/chronos';
import moment from 'moment';
import { combineLatest, map, OperatorFunction, shareReplay } from 'rxjs';
import { LocaleService } from './locale.service';

type FirstDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

const isValidFirstDayOfWeek = (value: number): value is FirstDayOfWeek =>
  value >= 0 && value <= 6;

@Injectable()
export class DateService {
  private readonly _locale = inject(LocaleService).localeId;
  private readonly _locale$ = toObservable(this._locale).pipe(shareReplay(1));
  /** Locale for moment.js */
  private readonly _momentLocale = this._locale;

  private readonly _dateFormat = computed(() =>
    moment.localeData(this._momentLocale()).longDateFormat('L'),
  );

  /**
   * Emits with the localized date format for inputs, which ensures to
   * use the padded versions of formats, so e.g. the format `D-M-YYYY`
   * becomes `DD-MM-YYYY`.
   */
  readonly dateInputFormat = computed(() =>
    toDateInputFormat(this._dateFormat()),
  );

  /** Day that the week starts on. */
  readonly weekStartsOn = computed(() => {
    const firstDayOfWeek = moment
      .localeData(this._momentLocale())
      .firstDayOfWeek();
    return isValidFirstDayOfWeek(firstDayOfWeek) ? firstDayOfWeek : 0;
  });

  constructor() {
    // Updates the locale internally maintained by moment.js
    effect(() => {
      moment.locale(this._momentLocale());
    });
  }

  /**
   * Using this ensures that the returned moment object
   * uses the correct locale.
   *
   * @returns
   * A new moment object
   */
  public moment(
    input: moment.MomentInput,
    format?: moment.MomentFormatSpecification,
    strict?: boolean,
  ): moment.Moment {
    return moment(input, format, strict).locale(this._momentLocale());
  }

  /**
   * @returns
   * Creates a localized moment object from each value
   * emitted by the source Observable and emits them.
   */
  public moment$(): OperatorFunction<moment.MomentInput, moment.Moment> {
    return (obs$) =>
      combineLatest([obs$, this._locale$]).pipe(map(([s]) => this.moment(s)));
  }

  /**
   * @param date Date that's inside the week to construct
   *
   * @returns
   * An array of all weekdays for the week of the given date.
   */
  public weekdays(date: moment.MomentInput): moment.Moment[] {
    const d = this.moment(date);
    return [0, 1, 2, 3, 4, 5, 6].map((day) => d.clone().weekday(day));
  }

  /**
   * @param date Date which lies inside the calendar week to construct
   *
   * @returns
   * The calendar week for a given date.
   */
  public calendarWeek(date: moment.MomentInput): CalendarWeek {
    const week = this.moment(date);
    return {
      weekNumber: week.week(),
      days: this.weekdays(week),
    };
  }

  /**
   * @param range Date range.
   *
   * @returns
   * An Array of all calendar weeks within the given range, using the current
   * locale.
   */
  public calendarWeeksForRange(range: TimeSpanInput): CalendarWeek[] {
    const [startInput, endInput] = range;

    const start = this.moment(startInput);
    const end = this.moment(endInput);

    const calendarWeeks: CalendarWeek[] = [];
    const week = start.weekday(0);
    while (week.isBefore(end, 'day')) {
      calendarWeeks.push(this.calendarWeek(week));
      week.add(1, 'week');
    }

    return calendarWeeks;
  }
}

function toDateInputFormat(format: string): string {
  return format
    .replace(/[D]+/i, 'DD')
    .replace(/[M]+/i, 'MM')
    .replace(/[Y]+/i, 'YYYY');
}
