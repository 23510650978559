import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PagedApiResponse } from '@fmnts/api';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { Observable } from 'rxjs';
import * as Api from './api-model';

@Injectable({ providedIn: 'root' })
export class AssignmentsApi {
  private readonly http = inject(HttpClient);
  private readonly apiHelper = inject(ApiRequestHelper);
  private readonly apiConfig = inject(ApiConfigService);

  static readonly rootUrl = '/v2/territory/assignments';

  create(dto: Api.CreateAssignmentDto): Observable<Api.Assignment> {
    const url = this.apiConfig.buildCockpitApiUrl([AssignmentsApi.rootUrl]);

    return this.http.post<Api.Assignment>(url, dto);
  }

  list(
    query: Api.AssignmentListQuery,
  ): Observable<PagedApiResponse<Api.Assignment>> {
    const url = this.apiConfig.buildCockpitApiUrl([AssignmentsApi.rootUrl]);
    const params = this.apiHelper.makeParams(query);

    return this.http.get<PagedApiResponse<Api.Assignment>>(url, { params });
  }
}
