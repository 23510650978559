import * as Brand from 'effect/Brand';
import * as Equivalence from 'effect/Equivalence';
import * as Function from 'effect/Function';

export type AbsoluteUrl = string & Brand.Brand<'AbsoluteUrl'>;
export const AbsoluteUrl = Brand.refined<AbsoluteUrl>(
  (u) => URL.canParse(u),
  (u) => Brand.error(`Expected ${u} to be an absolute Url`),
);

/**
 * Merges an URL that may be relative with a base.
 * If the URL is already absolute, base will be ignored
 *
 * @param maybeRelative The URL that is maybe relative
 * @param base The base URL
 */
export const mergeWithBase: {
  (base: AbsoluteUrl): (maybeRelative: string | URL) => URL;
  (maybeRelative: string | URL, base: AbsoluteUrl): URL;
} = Function.dual(
  2,
  (maybeRelative: string | URL, base: AbsoluteUrl) =>
    new URL(maybeRelative, base),
);

export function fromURL(url: URL): AbsoluteUrl {
  return AbsoluteUrl(url.toString());
}

/**
 * Parses an absolute URL string into a `URL`object.
 *
 * @param url The absolute URL
 */
export function fromAbsoluteUrl(url: AbsoluteUrl): URL {
  return new URL(url);
}

/**
 * URLs are equivalent if they serialize to the same string
 */
export const EquivalenceURL = Equivalence.mapInput(
  Equivalence.string,
  (url: URL) => url.toString(),
);

/**
 * Returns the stringified version of the URL without the origin
 *
 * @param url The URL instance
 * @returns The URL string without the origin
 */
export function getRelativePath(url: URL): string {
  return url.pathname + url.search + url.hash;
}

/**
 * Returns the last portion of an URL `pathname`
 *
 * @param url URL to be parsed
 *
 * @example
 * getBasename(new URL('https://example.com/foo/bar')) // => 'bar'
 */
export function getBasename(url: URL): string {
  return url.pathname.split('/').pop() ?? '';
}
